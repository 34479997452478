* {
    transition: all .3s ease;
    box-sizing: border-box;
}

html, body {
    background-color: #3e4041;
}

body {
    width: 100vw;
    height: 100vh;
    line-height: initial;
    font-family: sofia-pro, sans-serif;
    
    &.sesam-show #app {
        filter: blur(10px);
    }
}

img {
    user-select: none;
    -webkit-user-drag: none;
    pointer-events: none;
}

a, a:hover, a:active {
    color: inherit;
    text-decoration: none;
}

#app {
    color: white;
}