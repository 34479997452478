.modal {
    position: fixed;
    left: 0px; right: 0px; top: 0px; bottom: 0px;
    display: none;
    place-items: center;
    padding: 1.8rem;
    z-index: 10;

    &.sesam-show {
        display: grid;
    }
    
    &__header {
        margin-bottom: 2rem;
        
        h3 {
            // @include text-modern;
            // font-size: 1rem;
        }
    }
    
    &__content {
        background-color: white;
        border-radius: 14px;
        padding: 1.6rem;
        min-width: 50vw;
        max-width: 750px;
        
        h4 {
            @include text-modern;
            font-size: 1rem;
            font-weight: 600;
            color: grey;
            margin-bottom: 0.6rem;
        }
    }
    
    &__footer {
        display: flex;
        margin-top: 1.6rem;
        justify-content: center;
        
        .btn {
            margin-right: 1rem;
            
            &:last-child {margin-right: 0;}
        }
    }
}