.btn {
    display: block;
    width: fit-content;
    white-space: nowrap;
    background-color: #5050bf;
    text-transform: uppercase;
    color: white;
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 80%;
    
    &:active, &:hover, &:focus, &:focus-within {
        outline: none;
        box-shadow: none;
        color: white;
    }
    
    &:active {
        opacity: .7;
    }
    
    &--simple {
        position: relative;
        background-color: transparent;
        padding-left: 0px; padding-right: 0px;
        
        // &::after {
        //     content: '';
        //     position: absolute;
        //     bottom: 0px; left: 0px; right: 0px;
        //     width: 100%; height: 2px;
        //     background-color: currentColor;
        // }
    }
}